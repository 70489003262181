import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSubject = new Subject<boolean>();
  loaderSubject$ = this.loaderSubject.asObservable();

  emitIsLoading(isLoading: boolean) {
    this.loaderSubject.next(isLoading);
  }
}
